import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/medicina/:obrigado?",
		name: "medicina",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/medicina.vue"),
		meta: {
			description:
				"São mais de 20 especialidades médicas com agendamento rápido - Agende agora sua Consulta",
		},
	},
	{
		path: "/odonto/:obrigado?",
		name: "odonto",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/odonto.vue"),
		meta: {
			description:
				"+12 procedimentos Odontológicos com preços acessíveis - Agende sua avaliação sem custo",
		},
	},

	{
		path: "/exame/:obrigado?",
		name: "exame",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/exame.vue"),
		meta: {
			description:
				"Agende seus exames laboratoriais de rotina, e exames de imagem - Preços Acessíveis",
		},
	},

	{
		path: "/contato/:obrigado?",
		name: "contato",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/contato.vue"),
		meta: {
			description:
				"Sua saúde é muito importante para nós entre em contato conosco",
		},
	},
	{
		path: "/landingPage/:obrigado?",
		name: "landingPage",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/landingPage.vue"),
	},
	{
		path: "/novaLp/:obrigado?",
		name: "novaLp",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/novaLp.vue"),
	},
	{
		path: "/blog",
		name: "blog",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/BlogView.vue"),
		meta: {
			description: "Dicas de saúde pra você",
		},
	},
	{
		path: "/blog/:id/:article",
		name: "article",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/ArticleView.vue"),
	},
	{
		path: "/",
		name: "home",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/home.vue"),
		meta: {
			description:
				" É mais que uma clínica popular, é a  melhor clínica médica e odontológica de São José dos Campos. Venha cuidar da saúde da família aqui, consultas médicas a partir de R$70",
		},
	},

	{
		path: "/campanha/:obrigado?",
		name: "campanha",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/campanha.vue"),
		meta: {
			description:
				" É mais que uma clínica popular, é a  melhor clínica médica e odontológica de São José dos Campos. Venha cuidar da saúde da família aqui, consultas médicas a partir de R$70",
		},
	},
	{
		path: "/ginecologia/:obrigado?",
		name: "ginecologia",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/ginecologia.vue"),
		meta: {
			description:
				" É mais que uma clínica popular, é a  melhor clínica médica e odontológica de São José dos Campos. Venha cuidar da saúde da família aqui, consultas médicas a partir de R$70",
		},
	},
	{
		path: "/odontologia/:obrigado?",
		name: "odontologia",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/odontologia.vue"),
		meta: {
			description:
				" É mais que uma clínica popular, é a  melhor clínica médica e odontológica de São José dos Campos. Venha cuidar da saúde da família aqui, consultas médicas a partir de R$70",
		},
	},
	{
		path: "/obrigado",
		name: "obrigado",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/home.vue"),
		meta: {
			description:
				" É mais que uma clínica popular, é a  melhor clínica médica e odontológica de São José dos Campos. Venha cuidar da saúde da família aqui, consultas médicas a partir de R$70",
		},
	},
	{
		path: "/cardiologia/:obrigado?",
		name: "cardiologia",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/cardiologia.vue"),
		meta: {
			description:
				" É mais que uma clínica popular, é a  melhor clínica médica e odontológica de São José dos Campos. Venha cuidar da saúde da família aqui, consultas médicas a partir de R$70",
		},
	},
	{
		path: "/oftalmologia/:obrigado?",
		name: "oftalmologia",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/oftalmologia.vue"),
		meta: {
			description:
				" É mais que uma clínica popular, é a  melhor clínica médica e odontológica de São José dos Campos. Venha cuidar da saúde da família aqui, consultas médicas a partir de R$70",
		},
	},
	{
		path: "/dermatologia/:obrigado?",
		name: "dermatologia",
		component: () => import("../views/lps/dermatologia.vue"),
		meta: {
			description:
				" É mais que uma clínica popular, é a  melhor clínica médica e odontológica de São José dos Campos. Venha cuidar da saúde da família aqui, consultas médicas a partir de R$70",
		},
	},
	{
		path: "/neurologia/:obrigado?",
		name: "neurologia",
		component: () => import("../views/lps/neurologia.vue"),
		meta: {
			description:
				" É mais que uma clínica popular, é a  melhor clínica médica e odontológica de São José dos Campos. Venha cuidar da saúde da família aqui, consultas médicas a partir de R$70",
		},
	},
	{
		path: "/urologia/:obrigado?",
		name: "urologia",
		component: () => import("../views/lps/urologia.vue"),
		meta: {
			description:
				" É mais que uma clínica popular, é a  melhor clínica médica e odontológica de São José dos Campos. Venha cuidar da saúde da família aqui, consultas médicas a partir de R$70",
		},
	},
	// !-- MANTER COMENTADO SE FOR SOROCABA
	// {
	// 	path: "/reumatologia/:obrigado?",
	// 	name: "reumatologia",
	// 	component: () => import("../views/lps/reumatologia.vue"),
	// 	meta: {
	// 		description:
	// 			" É mais que uma clínica popular, é a  melhor clínica médica e odontológica de São José dos Campos. Venha cuidar da saúde da família aqui, consultas médicas a partir de R$70",
	// 	},
	// },
	// {
	// 	path: "/infectologia/:obrigado?",
	// 	name: "infectologia",
	// 	component: () => import("../views/lps/infectologia.vue"),
	// 	meta: {
	// 		description:
	// 			" É mais que uma clínica popular, é a  melhor clínica médica e odontológica de São José dos Campos. Venha cuidar da saúde da família aqui, consultas médicas a partir de R$70",
	// 	},
	// },
	// !-- MANTER COMENTADO SE FOR SOROCABA
	{
		path: "/ortopedia/:obrigado?",
		name: "ortopedia",
		component: () => import("../views/lps/ortopedia.vue"),
		meta: {
			description:
				" É mais que uma clínica popular, é a  melhor clínica médica e odontológica de São José dos Campos. Venha cuidar da saúde da família aqui, consultas médicas a partir de R$70",
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
